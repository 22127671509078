<template>
  <div>
    <h5>{{ concept }}</h5>
    <stick-form
      ref="elStickForm"
      @submitted="
        amountOverLoad = $event.overload;
        amountNoAffected = $event.noAffected;
      "
      :liquidationId="id"
      :disabled="output_id"
    >
      <template slot="amount">
        <tr>
          <th class="w-50">LIQUIDACION</th>
          <th class="text-right w-50">
            <app-span-money
              :quantity="amount"
              :moneyCode="money_code"
            ></app-span-money>
          </th>
        </tr>
      </template>
      <template slot="total">
        <!-- <tr>
          <td></td>
          <td>TOTAL CARGOS</td>
          <td class="text-right">
            <app-span-money
              :quantity="amountOverLoad"
              :moneyCode="money_code"
            ></app-span-money>
          </td>
        </tr> -->
        <tr>
          <th>TOTAL</th>
          <th class="text-right table-success">
            <app-span-money
              :quantity="amountCalculated"
              :moneyCode="money_code"
            ></app-span-money>
          </th>
        </tr>
      </template>
      <template slot="noAffected">
        <tr>
          <th>TOTAL NO AFECTO</th>
          <th class="text-right">
            <app-span-money
              :quantity="amountVirtual"
              :moneyCode="money_code"
            ></app-span-money>
          </th>
        </tr>
      </template>
    </stick-form>

    <div class="form-group d-print-none">
      <label for="">Medio de pago</label>
      <app-select-payment-method
        :disabled="output_id"
        v-model="method"
      ></app-select-payment-method>
    </div>
    <div class="form-group d-print-none">
      <label for="">Observaciones</label>
      <app-textarea :disabled="output_id" v-model="comment"></app-textarea>
    </div>
    <!-- <div class="form-group">
      <label for="">MONTO</label>
      <app-input-money
        :disabled="true"
        :quantity="amountCalculated"
        :moneyCode="money_code"
      ></app-input-money>
    </div> -->
    <app-button-submit
      class="d-print-none"
      v-show="!output_id"
      @click="letsPay()"
      text="Pagar"
    ></app-button-submit>
  </div>
</template>
<script>
import StickForm from "./overloads/StickForm";
import { AdminService } from "../AdminService";
// import SelectCashRegister from "../cashregisters/Select";

export default {
  components: {
    StickForm,
    // SelectCashRegister
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    id: null,
    concept: null,
    money_code: null,
    amount: null,
    method: 1,
    amountOverLoad: 0,
    amountNoAffected: 0,
    output_id: null,
    comment: null
  }),

  computed: {
    amountCalculated() {
      return parseFloat(this.amount) + parseFloat(this.amountOverLoad);
    },
    amountVirtual() {
      return (
        parseFloat(this.amountCalculated) + parseFloat(this.amountNoAffected)
      );
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(reg) {
      this.id = reg.id;
      this.concept = reg.concept;
      this.money_code = reg.money_code;
      this.amount = reg.amount;
      this.amountOverLoad = reg.amountOverLoad;
      this.amountNoAffected = reg.amountNoAffected;
      this.output_id = reg.output_id;
      this.comment = reg.comment;
    },
    letsPay() {
      AdminService.saveLiquidations({
        id: this.id,
        lets_pay: 1,
        payment_method_id: this.method,
        comment: this.comment
      }).then(output_id => {
        this.$emit("submitted");
        this.output_id = output_id;
      });
    }
  }
};
</script>

<style></style>
