<template>
  <div>
    <table class="table table-bordered table-sm">
      <slot name="amount"></slot>
      <!-- <tr>
        <th></th>
        <th>SOBRECARGOS</th>
        <th></th>
      </tr> -->
      <tr v-for="(l, i) in listAffected" :key="i">
        <td>
          <span>{{ l.concept }} </span>
          <a
            v-show="!disabled"
            href="javascript:void(0)"
            class="text-muted"
            @click="
              $refs.elPreDel.val = l.id;
              $refs.elPreDel.show();
            "
          >
            <i class="fa fa-trash"></i>
          </a>
        </td>
        <td class="text-right">
          <app-span-money
            :quantity="l.amount"
            :moneyCode="moneyCode"
          ></app-span-money>
        </td>
      </tr>
      <slot name="total"></slot>
      <template v-if="affect_options">
        <tr v-for="(l, i) in listNotAffected" :key="`${i}_1`">
          <td>
            <span>{{ l.concept }} </span>
            <a
              v-show="!disabled"
              href="javascript:void(0)"
              class="text-muted"
              @click="
                $refs.elPreDel.val = l.id;
                $refs.elPreDel.show();
              "
            >
              <i class="fa fa-trash"></i>
            </a>
          </td>
          <td class="text-right">
            <app-span-money
              :quantity="l.amount"
              :moneyCode="moneyCode"
            ></app-span-money>
          </td>
        </tr>
        <slot name="noAffected"></slot>
      </template>
      <tr class="d-print-none" v-show="affect_options && !disabled">
        <td colspan="2" class="text-center">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="affectRadious0"
              name="affectRadius"
              v-model="affect"
              :value="2"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="affectRadious0">
              Descuento
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="affectRadius2"
              name="affectRadius"
              v-model="affect"
              :value="1"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="affectRadius2">
              Sobrecargo
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="affectRadius1"
              name="affectRadius"
              v-model="affect"
              :value="0"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="affectRadius1">
              No afecto
            </label>
          </div>
        </td>
      </tr>
      <tr v-show="!disabled" class="d-print-none">
        <td class="p-0">
          <div class="input-group">
            <input
              type="text"
              class="form-control rounded-0"
              v-model="newConcept"
              placeholder="Nuevo Concepto"
            />
            <!-- <div class="input-group-append">
              <button class="btn btn-light"><i class="fa fa-link"></i></button>
            </div> -->
          </div>
        </td>
        <td class="p-0">
          <input
            type="text"
            pattern=".[0-9]+"
            step="0.01"
            class="form-control rounded-0"
            v-model="newAmount"
            @keyup.enter="addOverLoad()"
            placeholder="Monto"
          />
        </td>
      </tr>
    </table>

    <app-modal-yn ref="elPreDel" @yes="deleteOverLoad($refs.elPreDel.val)">
      <p>Eliminar sobre cargo</p>
    </app-modal-yn>
  </div>
</template>
<script>
import { AdminService } from "../../AdminService";
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    liquidationId: {},
    moneyCode: {},
    disabled: {
      default: false
    }
  },

  data: () => ({
    list: [],
    newConcept: null,
    newAmount: null,
    affect: 1
  }),

  computed: {
    ...mapState({
      affect_options: s => s.config.admin.liquidation.affect_options
    }),
    listAffected() {
      return this.list.filter(x => x.affect);
    },
    listNotAffected() {
      return this.list.filter(x => !x.affect);
    }
  },

  watch: {
    liquidationId() {
      this.reset();
    }
  },

  mounted() {
    //
  },

  methods: {
    deleteOverLoad(id) {
      AdminService.deleteLiquidationOverload(id).then(() => {
        this.getList().then(() => {
          this.emitSum();
        });
      });
    },
    reset(reloadList = true) {
      if (reloadList) {
        this.list = [];
        this.getList();
      }
      this.newConcept = null;
      this.newAmount = null;
    },
    getList() {
      return new Promise(rsv => {
        AdminService.getLiquidationOverloads({
          liquidation_id: this.liquidationId
        }).then(list => {
          this.list = list;
          rsv();
        });
      });
    },
    addOverLoad() {
      AdminService.saveLiquidationOverload({
        liquidation_id: this.liquidationId,
        amount:
          this.affect == 2
            ? parseFloat(this.newAmount) * -1
            : parseFloat(this.newAmount),
        concept: this.newConcept,
        affect: this.affect == 2 ? 1 : this.affect
      }).then(res => {
        this.list.push(res);
        this.reset(false);
        this.emitSum();
      });
    },
    emitSum() {
      this.$emit("submitted", {
        overload: this.listAffected
          .map(x => x.amount)
          .reduce((p, c) => p + parseFloat(c), 0),
        noAffected: this.listNotAffected
          .map(x => x.amount)
          .reduce((p, c) => p + parseFloat(c), 0)
      });
    }
  }
};
</script>

<style></style>
